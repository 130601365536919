import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    compose(
      applyMiddleware(sagaMiddleware)
    )
  );
  sagaMiddleware.run(rootSaga);
  store.dispatch({type: 'LOGOUT'});
  store.dispatch({type: 'LOGIN'});
  store.dispatch({type: 'LOGOUT'});
  return store;
};

export default configureStore;
