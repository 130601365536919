import React from "react";
import { FormattedMessage } from "react-intl";

function AppFooter() {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className="py-5 bg-purple">
        <div className="container">
          <p className="m-0 text-center text-white">
            <FormattedMessage
              id="label.copyright"
              defaultMessage="Copyright "
            />
            &copy; Ekō {year}
          </p>
        </div>
      </footer>
    </>
  );
}

export default AppFooter;
