import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppNavbar from '../../components/common/appNavbar';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
        acceptedTerms: false,
      },
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    console.log('', this.state.user);
  };

  handleInput = e => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value },
    });
  };

  handleCheckbox = e => {
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.checked },
    });
  };

  render() {
    return (
      <div>
        <AppNavbar />

        <div className="d-flex flex-row justify-content-center">
          <div className="d-inline-flex flex-column w-25 mt-5">
            <div className="p-2" style={{ height: '300px' }}>
              <h2 className="text-center">Login</h2>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="user@example.com"
                    value={this.state.user.email}
                    onChange={this.handleInput}
                  />
                  {/* <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small> */}
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="my!Guru%123"
                    value={this.state.user.password}
                    onChange={this.handleInput}
                  />
                  {/* <small id="passwordHelp" className="form-text text-muted">
                    Minimum 8 characters with 1 Uppercase and 1 Numeric.
                  </small> */}
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="acceptedTerms"
                    value={this.state.user.acceptedTerms}
                    onChange={this.handleCheckbox}
                  />
                  <label className="form-check-label">
                    {' '}
                    I agree the terms &amp; conditions.
                  </label>
                </div>
                <div className="form-group mt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ loginLoading, loginData, loginError }) => ({
  loginLoading,
  loginData,
  loginError,
});

export default connect(
  mapStateToProps,
  null
)(LoginContainer);
