import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';

// import logo from './logo.svg';
import AppRouter from './router';
import './App.css';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <AppRouter/>
    </Provider>
  );
}

export default App;
