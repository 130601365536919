import React from 'react';
// import { Router, Route, Switch, Link } from 'react-router'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams,
  // history,
  // useRouteMatch
} from 'react-router-dom';

import HomeContainer from './containers/home/homeContainer';
import DashboardContainer from './containers/dashboard/dashboardContainer';
import LoginContainer from './containers/login/loginContainer';

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <HomeContainer />
          </Route>
          <Route path="/dashboard">
            <DashboardContainer />
          </Route>
          <Route path="/login">
            <LoginContainer />
          </Route>
          {/*
            <Route path="/dashboard">
              <Dashboard />
            </Route>
          */}
        </Switch>
      </Router>
    );
  }
}
export default AppRouter;
