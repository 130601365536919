import { combineReducers } from 'redux';

import {
  loadLoginReducer,
  fetchLoginReducer,
  catchLoginReducer
} from './loginReducer';

const rootReducer = combineReducers({
  loginLoading: loadLoginReducer,
  loginData: fetchLoginReducer,
  loginError: catchLoginReducer
});

export default rootReducer;
