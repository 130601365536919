import { LOGIN } from '../constants';

export const loadLoginReducer = (state = false, action) => {
  switch (action.type){
    case LOGIN.LOADING:
      return true;
    case LOGIN.SUCCESS:
      return false;
    case LOGIN.FAILURE:
      return false;
    default:
      return state;
  }
};

export const fetchLoginReducer = (state = [], action) => {
  if (action.type === LOGIN.SUCCESS){
    return [...state, ...action.images];
  }
  return state;
};

export const catchLoginReducer = (state = null, action) => {
  if (action.type === LOGIN.FAILURE){
    return [...state, ...action.loginError];
  }
  return state;
};
