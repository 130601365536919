import React from "react";
import { FormattedMessage } from "react-intl";

const LeftContent = () => {
  return (
    <div className="col-md-6 mb-5">
      <div className="mb-5">
        <p className="mb-0 important-text">
          <FormattedMessage
            id="label.desc"
            defaultMessage="We’ve created a new tool to help Ekō members take on the biggest corporations in the world. With the new Member Video portal, you can send a personalized message directly to corporate CEOS, top Executives and political decision-makers."
          />
        </p>
      </div>
      <div>
        <h4>
          <FormattedMessage
            id="label.howWorksTitle"
            defaultMessage="Here’s how it works"
          />
        </h4>
      </div>
      <div>
        <p style={{ whiteSpace: "pre-line" }}>
          <FormattedMessage
            id="label.howWorksDesc"
            defaultMessage="Follow the instructions below to record a short message. We'll collect your videos and deliver them right to the target of the campaign, or simply share your story. TIPS: Take a minute or two to gather your thoughts. Make your video message come from the heart. Your personal story is what's going to have the biggest impact on decision-makers, so try to include examples of why this campaign is so important to you."
          />
          <br />
          <span style={{ color: "red" }}>
            <FormattedMessage
              id="label.howWorksDisclaimer"
              defaultMessage="* This tool is only compatible with Chrome/ Firefox web browsers."
            />
          </span>
        </p>
      </div>
      <div className="mt-5">
        <h5>
          <FormattedMessage id="label.step1" defaultMessage="STEP 1" />
        </h5>
      </div>
      <div>
        <p>
          <FormattedMessage
            id="label.step1Content"
            defaultMessage="When you're ready to record, click the “Start Recording” button below the video preview box. If you are prompted to enable your camera and microphone, please do so."
          />
        </p>
      </div>
      <div className="mt-5">
        <h5>
          <FormattedMessage id="label.step2" defaultMessage="STEP 2" />
        </h5>
      </div>
      <div>
        <p>
          <FormattedMessage
            id="label.step2Content1"
            defaultMessage="Share your story. If you’re comfortable, begin your video by saying your name and where in the world you are based. Note: You will only be able to record 1 minute of video, so please be concise."
          />
        </p>
        <p>
          <FormattedMessage
            id="label.step2Content2"
            defaultMessage="Click the red “Stop Recording” button when you are finished. Review your recording by pressing the play button in the video preview box. You can practice recording multiple times before you submit."
          />
        </p>
      </div>
      <div className="mt-5">
        <h5>
          <FormattedMessage id="label.step3" defaultMessage="STEP 3" />
        </h5>
      </div>
      <div>
        <p>
          <FormattedMessage
            id="label.step3Content"
            defaultMessage="Click 'Upload' when you’re ready to send us your video."
          />
        </p>
      </div>
      <div className="mt-5">
        <p style={{ color: "red" }}>
          <FormattedMessage
            id="label.disclaimer"
            defaultMessage="* By using this tool you give Ekō the right to use and distribute any and all content for purposes in line with our charirtable status"
          />
        </p>
      </div>
    </div>
  );
};

export default LeftContent;
