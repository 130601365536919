import React, { Component } from "react";

import AppNavbar from "../../components/common/appNavbar";
import AppFooter from "../../components/common/appFooter";

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <AppNavbar />
        <div>
          <div className="p-3 d-flex flex-column align-items-start">
            <div>
              <h2>Dashboard</h2>
            </div>

            <div>
              <h4>Options are yet to be available.</h4>
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }
}

export default DashboardContainer;
