import React, { Component } from "react";
import RecordRTC from "recordrtc";
import { FormattedMessage } from "react-intl";
import $ from "jquery";

import AppNavbar from "../../components/common/appNavbar";
import AppHeader from "../../components/common/appHeader";
import AppFooter from "../../components/common/appFooter";
import Amplify, { Storage } from "aws-amplify";
import awsmobile from "../../aws-exports";
import LeftContent from "./LeftContent";
import Counter from "./Counter";
Amplify.configure(awsmobile);

const hasGetUserMedia = !!(
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia
);

const userAgent = navigator.userAgent;

console.log(userAgent);

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.recorder = null;
    this.recordedBlob = null;
    this.stream = null;
    this.recordedDataURL = null;
    this.videoPlayer = React.createRef();
    this.videoFile = null;
    this.state = {
      showStartRecording: true,
      showStopRecording: false,
      showCancelRecording: false,
      showUploadRecording: false,
      uploadStatus: null,
      uploading: false,
      isIphoneSafari: false,
      muted: false,
      volume: 1,
      pickedFile: null,
      uploadProgress: 0,
    };
  }

  componentDidMount = () => {
    // Check if its a iPhone Safari browser
    const iPhoneFlag =
      userAgent.includes("iPhone") &&
      userAgent.includes("Mobile") &&
      userAgent.includes("Safari");
    this.setState({
      isIphoneSafari: iPhoneFlag,
    });

    // Show alert if its not a supported browser
    if (!iPhoneFlag && !hasGetUserMedia) {
      alert(
        "Your browser cannot stream from your webcam. Please switch to Chrome or Firefox."
      );
      return;
    }

    let cw =
      $(".video-container").width() - $(".video-container").width() / 5 - 8;
    $(".video-player").css({ height: cw + "px" });
  };

  handleStartRecording = () => {
    this.setState({
      uploadStatus: null,
      uploadProgress: 0,
    });
    console.log("starting recorder");
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          // width: { min: 1280, ideal: 1920, max: 1920 }, // Record min of 720p video and max of 1080p video
          // height: { min: 720, ideal: 1080, max: 1080 },
        },
        audio: true,
      })
      .then((stream) => {
        console.log("starting stream");
        this.setState({
          showStartRecording: false,
          showStopRecording: true,
          muted: true,
          volume: 0,
        });

        this.recorder = RecordRTC(stream, {
          type: "video",
          mimeType: "video/webm",
          bitsPerSecond: -5000,
          // audioBitsPerSecond: 6000, // min: 100bps max: 6000bps
          // videoBitsPerSecond: -5000, // min: -5000bps max: 100000bps
        });

        this.recorder.camera = stream;
        this.videoPlayer.current.srcObject = this.recorder.camera;
        this.recorder.startRecording();
      })
      .catch((e) => {
        console.log("Catch Error->", e);
      });
  };

  handleStopRecording = () => {
    this.recorder.stopRecording(() => {
      this.setState({
        showStopRecording: false,
        showUploadRecording: true,
        showCancelRecording: true,
        muted: false,
        volume: 1,
      });
      console.log(this.recorder);
      this.recordedBlob = this.recorder.getBlob();
      this.recorder.camera.stop();
      this.recorder.destroy();

      this.videoFile = new Blob([this.recordedBlob], { type: "video/webm" });
      this.videoPlayer.current.srcObject = null;
      this.videoPlayer.current.src = window.URL.createObjectURL(this.videoFile);
    });
  };

  handleCancelRecording = () => {
    window.location.reload();
  };

  handleUploadRecording = () => {
    this.setState({
      uploading: true,
      showStartRecording: false,
      uploadStatus: null,
      showCancelRecording: true,
      showUploadRecording: false,
    });
    if (this.state.pickedFile) {
      this.uploadPickedFile(this.state.pickedFile);
    } else {
      this.uploadRecordedFile();
    }
  };

  uploadRecordedFile = async () => {
    const fileName =
      Math.floor(Date.now()).toString() +
      Math.random().toString() +
      "recordedVideo.webm";

    // Assigning to set progress callback to the main state
    const parent = this;
    Storage.put(fileName, this.videoFile, {
      contentType: "video/webm",
      progressCallback(progress) {
        parent.setState({
          uploadProgress: parseInt((progress.loaded / progress.total) * 100),
        });
      },
    })
      .then((result) => {
        console.log(result);
        this.setState({
          showStartRecording: true,
          showUploadRecording: false,
          uploadStatus: "success",
          uploading: false,
          showCancelRecording: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          showUploadRecording: true,
          uploadStatus: "failure",
          uploading: false,
          showCancelRecording: true,
        });
      });
  };

  uploadPickedFile = async (file) => {
    if (file) {
      const blob = new Blob([file], { type: file.type });
      const fileType = file.type.substring(6);
      const fileName = `${Math.floor(Date.now()).toString()}-${file.name
        .replace(/[^\w]/g, "")
        .replace(fileType, "")}.${fileType}`;

      // Assigning to set progress callback to the main state
      const parent = this;
      Storage.put(fileName, blob, {
        contentType: file.type,
        progressCallback(progress) {
          parent.setState({
            uploadProgress: parseInt((progress.loaded / progress.total) * 100),
          });
        },
      })
        .then((result) => {
          console.log(result);
          this.setState({
            showStartRecording: true,
            showUploadRecording: false,
            uploadStatus: "success",
            uploading: false,
            showCancelRecording: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            showUploadRecording: true,
            uploadStatus: "failure",
            uploading: false,
            showCancelRecording: true,
          });
        });
    }
  };

  handleFilePick = (event, isIosSafari) => {
    let files;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    this.setState({
      pickedFile: files[0],
      showStartRecording: false,
      showCancelRecording: true,
      showUploadRecording: true,
      uploadStatus: null,
      uploadProgress: 0,
    });
    const video = window.URL.createObjectURL(files[0]);
    this.videoPlayer.current.src = video;
    // If its picked from iOS mobile upload directly to avoid more interactions
    if (isIosSafari) {
      this.uploadPickedFile(files[0]);
    }
  };

  render() {
    return (
      <div>
        <AppNavbar />
        <AppHeader />
        <div className="container">
          <div className="row">
            <LeftContent />

            {/* Right Content */}
            <div className="col-md-6 mb-5">
              <div className="mb-3">
                <h2 className="text-center">
                  <FormattedMessage
                    id="label.recordStory"
                    defaultMessage="Record Your Story"
                  />
                </h2>
                <hr />
                <div className="shadow video-container rounded mb-0">
                  <video
                    className="video-player"
                    ref={this.videoPlayer}
                    controls={!this.state.showStopRecording}
                    autoPlay
                    muted={this.state.muted}
                    volume={this.state.volume}
                  ></video>
                </div>

                {/* Action buttons that will be shown on all browsers except iOS safari */}
                {!this.state.isIphoneSafari && (
                  <div className="my-2">
                    <div className="mt-3 text-center">
                      <button
                        id="btn-start-recording"
                        onClick={this.handleStartRecording}
                        className={`shadow btn eko-btn ${
                          this.state.showStartRecording ? "show" : "hide"
                        }`}
                      >
                        <i className="fas fa-play"></i> &nbsp;
                        <FormattedMessage
                          id="label.startRecording"
                          defaultMessage="Start Recording"
                        />
                      </button>
                      {this.state.showStopRecording && <Counter />}
                      <button
                        id="btn-stop-recording"
                        onClick={this.handleStopRecording}
                        className={`shadow btn btn-danger ${
                          this.state.showStopRecording ? "show" : "hide"
                        }`}
                      >
                        <i className="fas fa-stop"></i> &nbsp;
                        <FormattedMessage
                          id="label.stopRecording"
                          defaultMessage="Stop Recording"
                        />
                      </button>
                      <button
                        id="btn-cancel-recording"
                        onClick={this.handleCancelRecording}
                        className={`shadow btn btn-light mr-2 ${
                          this.state.showCancelRecording ? "show" : "hide"
                        }`}
                      >
                        <FormattedMessage
                          id="label.cancel"
                          defaultMessage="Cancel"
                        />
                      </button>
                      <button
                        id="btn-uploading-recording"
                        className={`shadow btn btn-secondary ${
                          this.state.uploading ? "show" : "hide"
                        }`}
                        disabled
                      >
                        <FormattedMessage
                          id="label.uploading"
                          defaultMessage="Uploading"
                        />
                        {this.state.uploadProgress}%
                      </button>
                      <button
                        id="btn-accept-recording"
                        onClick={this.handleUploadRecording}
                        className={`shadow btn eko-btn ${
                          this.state.showUploadRecording ? "show" : "hide"
                        }`}
                      >
                        <FormattedMessage
                          id="label.upload"
                          defaultMessage="Upload"
                        />
                      </button>
                    </div>

                    {/* Success Msg */}
                    <div
                      className={`width-100 mt-3 mb-2 text-center mt-2 alert alert-success ${
                        this.state.uploadStatus === "success" ? "show" : "hide"
                      }`}
                      role="alert"
                    >
                      <FormattedMessage
                        id="label.successMsg"
                        defaultMessage="We received your story! Thank you for submitting."
                      />
                    </div>
                    {/* Failure Msg */}
                    <div
                      className={`width-100 mt-3 mb-2 text-center mt-2 alert alert-danger ${
                        this.state.uploadStatus === "failure" ? "show" : "hide"
                      }`}
                      role="alert"
                    >
                      <FormattedMessage
                        id="label.failureMsg"
                        defaultMessage="Upload failed. Please try again."
                      />
                    </div>
                    {/* End of Msgs */}
                    <hr />
                  </div>
                )}
              </div>

              {/* 
                  Block to handle IOS safari browser recording
                  Opens the default camera and records from there, on clicking use video it uploads directly to S3 bucket as quicktime video
               */}
              {this.state.isIphoneSafari && (
                <>
                  <div className="iosSafari">
                    {this.state.uploading ? (
                      <button
                        id="btn-uploading-recording"
                        className={`shadow btn btn-secondary ${
                          this.state.uploading ? "show" : "hide"
                        }`}
                        disabled
                      >
                        <FormattedMessage
                          id="label.uploading"
                          defaultMessage="Uploading"
                        />
                        {this.state.uploadProgress}%
                      </button>
                    ) : (
                      <>
                        <div className="shadow btn eko-btn show">
                          <FormattedMessage
                            id="label.startRecording"
                            defaultMessage="Start Recording"
                          />
                        </div>
                        <input
                          id="videoFile"
                          type="file"
                          className="hidden"
                          accept="video/*"
                          capture=""
                          onChange={(ev) => this.handleFilePick(ev, true)}
                        />
                      </>
                    )}
                  </div>
                  {this.state.uploadStatus !== null && (
                    <>
                      <div
                        className={`width-100 mt-3 mb-2 text-center mt-2 alert alert-success ${
                          this.state.uploadStatus === "success"
                            ? "show"
                            : "hide"
                        }`}
                        role="alert"
                      >
                        <FormattedMessage
                          id="label.successMsg"
                          defaultMessage="We received your story! Thank you for submitting."
                        />
                      </div>
                      <div
                        className={`width-100 mt-3 mb-2 text-center mt-2 alert alert-danger ${
                          this.state.uploadStatus === "failure"
                            ? "show"
                            : "hide"
                        }`}
                        role="alert"
                      >
                        <FormattedMessage
                          id="label.failureMsg"
                          defaultMessage="Upload failed. Please try again."
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {/* End of IOS safari block */}

              {!this.state.uploading && (
                <div className="mt-5 text-center">
                  <h4>
                    <FormattedMessage
                      id="label.uploadNow"
                      defaultMessage="or Upload Now"
                    />
                  </h4>
                  <div className="mt-2 text-center">
                    <label for="filePicker" class="shadow btn eko-btn">
                      <FormattedMessage
                        id="label.chooseFile"
                        defaultMessage="Choose File"
                      />
                    </label>
                    <input
                      id="filePicker"
                      type="file"
                      className="ml-5"
                      accept="video/*"
                      style={{ display: "none" }}
                      onChange={(ev) => this.handleFilePick(ev, false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    );
  }
}

export default HomeContainer;
