import { take, put, call } from "redux-saga/effects";

//worker
function* workerSaga() {
  // console.log('workerSaga worker says hello!');
  // console.log(put({type: 'MOCK_ACTION'}));
  yield put({ type: "CHUMMA" });
}

function* dashboardSaga() {
  // console.log('dashboardSaga worker says hello!');
  // console.log(put({type: 'MOCK_ACTION'}));
  yield put({ type: "CHUMMA" });
}

//watcher
function* rootSaga() {
  // console.log('Saga watcher says hello!');
  // yield takeEvery('om', workerSaga);
  yield take("LOGIN");
  yield call(workerSaga);
  yield take("LOGOUT");
  yield call(dashboardSaga);
}

export default rootSaga;
