import React, { useContext } from "react";
import { LangContext } from "../../services/lang.service";

function AppNavbar() {
  const langContext = useContext(LangContext);
  const { lang, setLang } = langContext;

  return (
    <>
      <nav className="shadow navbar navbar-expand-lg navbar-dark bg-purple fixed-top">
        <div className="container">
          <a className="navbar-brand" href={"/"}>
            <img
              src="https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_White.svg"
              alt="Ekō Logo"
              className="nav-logo-img"
            />
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.eko.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  🌐 Ekō
                </a>
              </li>
              {/* <li className="nav-item active">
                <a className="nav-link" href={"/"}>
                  Share Your Story
                  <span className="sr-only">(current)</span>
                </a>
              </li> */}
            </ul>
          </div>
          <select
            id="lang"
            name="lang"
            value={lang}
            onChange={(e) => {
              setLang(e.target.value);
              localStorage.setItem("locale", e.target.value);
            }}
            className="lang-dropdown"
          >
            <option value="en">ENGLISH</option>
            <option value="de">DEUTSCH</option>
            <option value="fr">FRANÇAIS</option>
            <option value="es">ESPAÑOL</option>
            {/* <option value="pt">PORTUGUÊS</option> */}
          </select>
        </div>
      </nav>
    </>
  );
}

export default AppNavbar;
