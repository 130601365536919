import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <header className="bg-lavendar py-5 mb-5">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-12 text-center">
                <span className="display-4 text-black font-weight-bold mt-5 mb-2">
                  <FormattedMessage
                    id="label.title"
                    defaultMessage="Share your story with Ekō"
                  />
                </span>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default AppHeader;
