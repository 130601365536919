import React, {useEffect, useState} from "react";

const Counter = () => {
    const [counter, setCounter] = useState(-1);

    function calculateTimeDuration(secs) {
        var hr = Math.floor(secs / 3600);
        var min = Math.floor((secs - (hr * 3600)) / 60);
        var sec = Math.floor(secs - (hr * 3600) - (min * 60));
    
        if (min < 10) {
            min = "0" + min;
        }
    
        if (sec < 10) {
            sec = "0" + sec;
        }
    
        if(hr <= 0) {
            return min + ' : ' + sec;
        }
    
        return hr + ' : ' + min + ' : ' + sec;
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(counter => counter + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return  counter > 0 ? <h4>{calculateTimeDuration(counter)}</h4> : null

};

export default Counter;
