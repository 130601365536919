// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0dbe3956-c30d-46fe-a856-d17fdbad5bb9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ZHeaOZ6eY",
    "aws_user_pools_web_client_id": "6d05dgc591gvn1af864o4hv99l",
    "oauth": {},
    "aws_user_files_s3_bucket": "storyvideos81932-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
